import { setReportsFields } from '../actions'
import { API, store } from '../config'
import { jwtAuth } from './auth'
import { handleAPIError } from './helper'

export const getErrorReport = async () => {
  try {
    const res = await window.fetch(`${API}/dashboard/error-report`, {
      method: 'GET',
      headers: jwtAuth()
    })
    const response = await res.json()
    if (!res.ok) throw new Error(response.message)
    store.dispatch(setReportsFields({ errorReport: response.errorReport }))
    return response.errorReport
  } catch (err) {
    handleAPIError(err)
    return null
  }
}
