import { loginTypes, reportsTypes } from '../actions'

const initialState = {
  errorReport: undefined
}

const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case reportsTypes.SET_REPORTS_FIELDS:
      return { ...state, ...payload }
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default reports
