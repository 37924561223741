import React, { useState } from 'react'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateInvoice } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import moment from 'moment'
import _ from 'lodash'
import { CURRENCIES, INVOICE_CATEGORIES } from '../../../config/constants'
import { createInvoice } from '../../../actions'

// const EX = {
//   clinicalResearches: [],
//   payment: '',
//   name: '',
//   date: '',
//   amount: 0,
//   currency: 'BGN',
//   received: false,
//   notes: ''
// }

function InvoiceForm (props) {
  const { clinicalResearches, payments, companies, onHide } = props

  const [query, setQuery] = useState({ clinicalResearches: [], currency: 'BGN', amount: 0, category: 'visit' })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    if (field === 'clinicalResearches' && value) {
      const crs = query.clinicalResearches.includes(value) ? query.clinicalResearches.filter(e => e !== value) : [...query.clinicalResearches, value]
      const _query = validateInvoice({ ...query, clinicalResearches: crs })
      setQuery(_query)
      return
    }
    const _query = validateInvoice({ ...query, [field]: value })
    if (field === 'amount' || field === 'amountPI') {
      if (_query[field] === undefined) {
        _query[field] = 0
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createInvoice({
      data: validateInvoice(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const sortedCompanies = companies.slice(0).sort((a, b) => a.name.localeCompare(b.name))

  const disabled = !query.clinicalResearches.length || !query.name || !query.date
  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            {t('fields.clinicalResearches')}*
          </Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className='bg-white text-black'
              style={{ width: '100%', alignSelf: 'flex-start', display: 'flex', alignItems: 'center', borderColor: 'lightGrey' }}
            >
              {query.clinicalResearches.length ? query.clinicalResearches.map(id => clinicalResearches.find(item => item._id === id)?.name).join('; ') : t('common.select')}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ maxHeight: '450px', overflowY: 'auto' }}
            >
              {clinicalResearches.map(cr => {
                return (
                  <Dropdown.Item
                    onClick={() => handleUpdateQuery('clinicalResearches', cr._id)}
                    className={query.clinicalResearches.includes(cr._id) ? 'bg-primary text-white' : ''}
                    key={cr._id}
                    value={cr._id}
                  >
                    {cr.name}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          <br />
          <Form.Label>
            {t('fields.payment')}
          </Form.Label>
          <Form.Select
            value={query.payment}
            onChange={e => handleUpdateQuery('payment', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {payments
              .filter(p => !p.completed)
              .map(p => {
                return (
                  <option
                    key={p._id}
                    value={p._id}
                  >
                    {p.reason}
                  </option>
                )
              })}
          </Form.Select>
        </Col>
        <Row>
          <Col>
            <br />
            <Form.Label>
              {t('fields.name')}*
            </Form.Label>
            <Form.Control
              type='text'
              onChange={e => handleUpdateQuery('name', e.target.value)}
            />
            <br />
            <Form.Label>
              {t('fields.amount')}
            </Form.Label>
            <Form.Control
              type='number'
              onChange={e => handleUpdateQuery('amount', e.target.value)}
              onFocus={e => e.target.select()}
            />
            <br />

            <Form.Label>
              {t('fields.received')}
            </Form.Label>
            <Form.Select
              value={query.completed}
              onChange={e => handleUpdateQuery('received', e.target.value === 'true')}
            >
              <option value='false'>{t('common.no')}</option>
              <option value='true'>{t('common.yes')}</option>
            </Form.Select>
            <br />
            <Form.Label>
              {t('fields.notes')}
            </Form.Label>
            <Form.Control
              type='text'
              onChange={e => handleUpdateQuery('notes', e.target.value)}
            />
            <br />
            <Form.Label>
              {t('fields.amountPI')}
            </Form.Label>
            <Form.Control
              type='number'
              onChange={e => handleUpdateQuery('amountPI', e.target.value)}
              onFocus={e => e.target.select()}
            />
          </Col>
          <Col>
            <br />
            <Form.Label>
              {t('fields.date')}*
            </Form.Label>
            <Form.Control
              type='date'
              onChange={e => handleUpdateQuery('date', moment(e.target.value, 'YYYY-MM-DD').toDate())}
            />
            <br />
            <Form.Label>
              {t('fields.currency')}
            </Form.Label>
            <Form.Select
              value={query.currency}
              onChange={e => handleUpdateQuery('currency', e.target.value)}
            >
              <option value=''>{t('common.select')}...</option>
              {CURRENCIES.map(c => {
                return (
                  <option
                    key={c}
                    value={c}
                  >
                    {c}
                  </option>
                )
              })}
            </Form.Select>
            <br />
            <Form.Label>
              {t('fields.category')}
            </Form.Label>
            <Form.Select
              value={query.category}
              onChange={e => handleUpdateQuery('category', e.target.value)}
            >
              <option value=''>{t('common.select')}...</option>
              {INVOICE_CATEGORIES.map(c => {
                return (
                  <option
                    key={c}
                    value={c}
                  >
                    {_.capitalize(c)}
                  </option>
                )
              })}
            </Form.Select>
            <br />
            <Form.Label>
              {t('fields.billTo')}
            </Form.Label>
            <Form.Select
              value={query.company}
              onChange={e => handleUpdateQuery('company', e.target.value)}
            >
              <option value=''>{t('common.select')}...</option>
              {sortedCompanies
                .map(c => {
                  return (
                    <option
                      key={c._id}
                      value={c._id}
                    >
                      {c.name}
                    </option>
                  )
                })}
            </Form.Select>
          </Col>
        </Row>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  companies: state.dashboard.companies,
  payments: state.dashboard.payments
})

const mapDispatchToProps = dispatch => ({
  createInvoice: payload => dispatch(createInvoice(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm)
