import { combineReducers } from 'redux'
import general from './general'
import login from './login'
import dashboard from './dashboard'
import treasury from './treasury'
import modal from './modal'
import utility from './utility'
import reports from './reports'

export default combineReducers({
  general,
  login,
  dashboard,
  treasury,
  utility,
  modal,
  reports
})
